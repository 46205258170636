.popup {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  z-index: 999;
  background-color: rgba(12, 12, 11, 0.8);
  color: #fff;
  overflow: hidden;
}

.popup__hidden {
  display: none;
  overflow: auto;
}

.popup__form {
  background-color: #fff;
  max-width: 70%;
  padding: 20px;
  border-radius: 10px;
}

.checkbox-wrapper-18 .round {
  position: relative;
}

.checkbox-wrapper-18 .round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  width: 28px;
  display: block;
}

.checkbox-wrapper-18 .round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 8px;
  opacity: 0;
  position: absolute;
  top: 9px;
  transform: rotate(-45deg);
  width: 12px;
}

.checkbox-wrapper-18 .round input[type="radio"] {
  visibility: hidden;
  display: none;
  opacity: 0;
}

.checkbox-wrapper-18 .round input[type="radio"]:checked + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.checkbox-wrapper-18 .round input[type="radio"]:checked + label:after {
  opacity: 1;
}

.checkbox-wrapper-18 {
  display: flex;
  align-items: center;
}

.round {
  margin-right: 10px;
}

@media screen and (max-width: 550px) {
  .checkbox-wrapper-18 .round label {
    width: 18px;
    height: 18px;
  }

  .checkbox-wrapper-18 .round label:after {
    left: 3px;
    top: 5px;
  }
}

@import url("./components/Checkbox/Checkbox.css");
@import url("./components/Radio/Radio.css");
body {
  height: 100%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

::-webkit-scrollbar {
  display: none;
}

.App {
  text-align: center;
  background: linear-gradient(to bottom right, #c10c1a, #1d8fcf);
  height: 100%;
}

.App-logo {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form {
  padding-top: 10px;
  max-width: 35%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form__label {
  font-size: 13px;
  line-height: 1.2;
  color: #8b8b8b;
  margin-right: 15px;
}

.form__input,
.form__select {
  margin: 10px 0;
  border: none;
  outline: none;
  background-color: #2f2f2f;
  width: 100%;
  border-radius: 8px;
  font-size: 13px;
  line-height: 1.23;
  padding: 15px;
  box-sizing: border-box;
  color: #ffffff;
}

.form__input_textarea {
  min-height: 200px;
}

.form__input:focus {
  outline: 1px solid #3ddc84;
}

.form__input_type_error {
  color: #ee3465;
  outline: 1px solid #ee3465;
}

.form__send {
  padding: 13px 125px;
  margin: 20px 0;
  /* width: 100%; */
  background-color: #4285f4;
  outline: none;
  border: none;
  border-radius: 3px;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.21;
  cursor: pointer;
  transition: opacity 0.3s ease 0.1s;
}

.form__reset {
  background-color: #ee3465;
}

.form__send_disabled {
  background-color: #8b8b8b;
  pointer-events: none;
}

.form__send:hover {
  opacity: 0.5;
}

.form__text {
  font-size: 14px;
  line-height: 1.21;
  font-weight: 400;
  text-align: center;
  color: #8b8b8b;
}

.form__link {
  cursor: pointer;
  font-size: 14px;
  line-height: 1.21;
  color: #4285f4;
  margin-left: 9px;
  transition: opacity 0.3s ease 0.1s;
}

.form__link:hover {
  opacity: 0.5;
}

.form__error {
  display: none;
}

.form__error_visible {
  display: block;
  font-size: 15px;
  line-height: 1.2;
  color: #ee3465;
}

.container {
  position: relative;
  width: 100%;
}

.suggestions {
  display: flex;
  flex-direction: column;
  z-index: 999;
  list-style: none;
  text-align: left;
  background-color: #2f2f2f;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  position: absolute;
  max-height: 120px;
  overflow-y: scroll;
}

.suggestions_item {
  display: flex;
  align-items: center;
  padding-left: 15px;
  border: 1px solid;
  height: max-content;
  cursor: pointer;
  /* opacity: 1; */
  flex-direction: column;
  color: white;
}

.address__suggestions_item:hover {
  opacity: 0.5;
  transition: opacity 0.5s ease-in-out;
}

#hide_suggestions_button {
  margin-top: 200px;
}

.opts {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.addrTypeRadio,
.shipmentTypeRadio {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
}

.hidden {
  display: none;
}

@media screen and (max-width: 1500px) {
  .form {
    max-width: 50%;
  }
}

@media screen and (max-width: 1000px) {
  .form {
    max-width: 70%;
  }
}

@media screen and (max-width: 750px) {
  .form {
    max-width: 95%;
  }
}

@media screen and (max-width: 550px) {
  .shipmentTypeRadio {
    flex-wrap: wrap;
    gap: 10px 0;
  }
  .form__send {
    padding: 13px 70px;
  }
}

@media screen and (max-width: 360px) {
  .form__send {
    padding: 13px 30px;
  }
}
